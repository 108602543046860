import React from "react";
import ProductHero from "../../components/products/hero";
import TwoColumns from "../../components/products/banner";
import Container from "../../layout/container";
import Benefits from "../../components/benefit";
import Meta from "../../components/common/meta";
const data = [
  {
    id: 1,
    title: "新しいDevOpsオペレーティングモデルを安全に実現できます",
    description:
      "厳格な規則に沿った安全な環境でもセルフサービスのメリットを享受できます。Rundeckは、コンプライアンス管理を維持しながら、運用方法を再考するのに役立ちます。作業の流れを安全に再編成して、スループットと品質を向上させ、構築、実行のように新しいオペレーティングモデルを活用します。",
    isReverse: true,
    image: "/products/security/running_a_job.webp",
  },
  {
    id: 2,
    title: "自動化の制御の改善",
    description:
      "Rundeckを使用すると、下位レベルの自動化の手段（スクリプト、ツール、API）と、その自動化を使って作業する必要がある人との間に、制御用のレイヤーを安全に挿入できます。",
    isReverse: false,
    image: "/products/security/second.webp",
  },
  {
    id: 3,
    title: "監査用のビルトインアクティビティログ",
    description:
      "過去のすべてのジョブとコマンドの実行を監査および検索します。ジョブの作成者、ジョブの実行者、ジョブが呼び出した対象（ツール、スクリプト、コマンド、APIなど）、実行された場所、さらに何が起きたかを正確に把握します。",
    isReverse: true,
    image: "/products/security/third.webp",
  },
  {
    id: 4,
    title: "機密データへのアクセスのより厳密な制御",
    description:
      "RundeckのKey Storageを使用すると、より多くのユーザーがRundeckジョブに同時にアクセスできるようにする一方で、機密データにアクセスできるユーザーをより厳密に制御できるようにしますので、自動化へのアクセスが容易に管理できます。",
    isReverse: false,
    image: "/products/security/fourth.webp",
  },
];
const heroData = {
  title: "厳格な規則に沿った安全な環境でのセルフサービス",
  description: "ビルドを安全に、チームで実行できるようにします",
  bg: "/products/security/security.svg",
};
const benefitData = {
  title: "コンプライアンスとセキュリティーの強化",
  cards: [
    {
      id: 1,
      title: "より速く、より強固に",
      description:
        "Rundeckの全てのアクションの完全な監査証跡により、セキュリティーとコンプライアンスの監視を実現します。",
    },
    {
      id: 2,
      title: "きめ細かいロールベースのアクセスコントロール",
      description:
        "オペレーション権限を他のチームやビジネスユニットに安全に拡張可能。何を、誰が、どのように実行するかを管理できます。",
    },
    {
      id: 3,
      title: "エラーや矛盾を減らす",
      description:
        "Rundeckに組み込まれた標準作業手順により、ヒューマンエラーを減らすことができます。",
    },
  ],
};
const defaultMeta = {
  title:
    "セキュリティーとコンプライアンスの自動化｜Rundeck正規販売代理店 DXable",
  description:
    "Rundeckは、インシデント復旧時間とコストの大幅削減を実現するランブックオートメーションツールです。スクリプトやcronによる定型作業のランブック登録で、誰でも安全にタスクを実行できます。",
  url: "/security-and-compliance",
};
const SecurityAndCompliance = () => {
  return (
    <Container>
      <Meta meta={defaultMeta} />
      <ProductHero {...heroData} />
      <div className="md:px-8">
        <Benefits data={benefitData}></Benefits>
        <div className="p-4 md:p-0">
          {data.map((sec) => (
            <TwoColumns
              key={sec.id}
              isColReverse={sec.isReverse}
              image={sec.image}
              title={sec.title}
              description={sec.description}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};
export default SecurityAndCompliance;
